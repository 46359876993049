import React from "react";
import { CFooter, CImage } from "@coreui/react";
import m3trikfooter from "../assets/M3TRIKFOOTER.png";

const AppFooter = () => {
  return (
    <CFooter>
      <div className="ms-auto">
        <CImage
          className="footericon"
          fluid
          width={70}
          height={70}
          src={m3trikfooter}
        />
        <span className="me-1"> &copy; 2021</span>
      </div>
    </CFooter>
  );
};

export default React.memo(AppFooter);
