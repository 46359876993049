import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "../scss/style.scss";
import { AuthProvider } from "../Contexts/Auth";
import PrivateRoute from "./PrivateRoute";
import Connect from "../pages/Connect";
import Home from "../pages/Home";
import Login from "../pages/Login.jsx";
import Register from "../pages/Register.jsx";
import ForgotPassword from "../pages/ForgotPassword.jsx";
import CustomersMeli from "../pages/MeliPages/Customers.jsx";
import ProductsMeli from "../pages/MeliPages/Products.jsx";
import SellsMeli from "../pages/MeliPages/Sells";
import ShipmentStatusMeli from "../pages/MeliPages/ShipmentStatus";
import SuccessMeli from "../pages/MeliPages/SuccessMeli";
// import ProductsAmazon from "../pages/AmazonPages/Products.jsx";
// import SellsAmazon from "../pages/AmazonPages/Sells";
// import ShipmentStatusAmazon from "../pages/AmazonPages/ShipmentStatus";


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

function Routes() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <PrivateRoute exact path="/connect" component={Connect} />
            <PrivateRoute exact path="/" component={Home} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/forget-password" component={ForgotPassword} />
            <PrivateRoute exact path="/mlventasglobales" component={SellsMeli} />
            <PrivateRoute exact path="/mlclientesrecurrencia" component={CustomersMeli} />
            <PrivateRoute exact path="/mlproductos" component={ProductsMeli} />
            <PrivateRoute exact path="/success-meli" component={SuccessMeli} />
            <PrivateRoute exact path="/mlshipmentstatus" component={ShipmentStatusMeli} />
            {/* <PrivateRoute exact path="/aproductos" component={ProductsAmazon} />
            <PrivateRoute exact path="/aventasglobales" component={SellsAmazon} />
            <PrivateRoute exact path="/ashipmentstatus" component={ShipmentStatusAmazon} /> */}
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    </AuthProvider>
  );
}
export default Routes;
