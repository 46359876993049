import React from "react";
import AppHeader from "../../Layout/AppHeader";
import AppFooter from "../../Layout/AppFooter";
import AppSidebar from "../../Layout/AppSidebar";
import ShipmentStatusPieChart from "../../components/charts/ShipmentStatusPieChart";
import ShipmentStatusPieChartTrackingMethod from "../../components/charts/ShipmentStatusPieChartTrackingMethod";
import { Col, Row } from "react-bootstrap";

const Rentabilidad = () => {
  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <Row>
            <Col>
              <h3>Estado de pedidos</h3>
              <ShipmentStatusPieChart delivered={"delivered"} route={"/meliorders"}/>
            </Col>
            <Col>
              <h3>Método de envio</h3>
              <ShipmentStatusPieChartTrackingMethod route={"/meli/shipment"} />
            </Col>
          </Row>
        </div>
        <AppFooter />
      </div>
    </div>
  );
};

export default Rentabilidad;
