import React, { useState } from "react";
import AppHeader from "../Layout/AppHeader";
import AppFooter from "../Layout/AppFooter";
import AppSidebar from "../Layout/AppSidebar";
// import { ButtonGroup, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import WidgetSells from "../components/charts/WidgetSells";
import WidgetTicket from "../components/charts/WidgetTicket";
import WidgetPieces from "../components/charts/WidgetPieces";
import SellsThisPeriod from '../components/charts/SellsThisPeriod';
import ShipmentStatusPieChart from '../components/charts/ShipmentStatusPieChart';
import ProductsPareto from '../components/charts/ProductsPareto';
// import WidgetSellsAll from "../components/charts/AllCharts/WidgetSellsAll";
// import WidgetTicketAll from "../components/charts/AllCharts/WidgetTicketAll";
// import WidgetPiecesAll from "../components/charts/AllCharts/WidgetPiecesAll";
// import SellsThisPeriodAll from "../components/charts/AllCharts/SellsThisPeriodAll";
// import SellsPieAll from "../components/charts/AllCharts/SellsPieAll";
// import ProductsParetoAll from "../components/charts/AllCharts/ProductsParetoAll";

const Home = () => {
  const [view, setView] = useState("todos");
  const history = useHistory();

  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        {/* <div className="toggle">
          <ButtonGroup className="mb-2">
            <Button
              variant="dark"
              name="mercado"
              value="mercado"
              onClick={(e) => setView("meli")}
            >
              Mercado Libre
            </Button>
            <Button
              variant="dark"
              name="amazon"
              value="amazon"
              onClick={(e) => setView("amazon")}
            >
              Amazon
            </Button>
            <Button
              variant="dark"
              name="todos"
              value="todos"
              onClick={(e) => setView("todos")}
            >
              Todos
            </Button>
          </ButtonGroup>
        </div> */}
        {/* {
          view === "amazon" ?
            <>
              <center><h4>Amazon</h4></center>
              <div className="body flex-grow-1 px-3">
                <div className="row ">
                  <div className="col-xl-4 col-lg-6" onClick={() => history.push("/aventasglobales")}>
                    <WidgetSells route={"/amazon/consolidatedamazon"} view={"amazon"} />
                  </div>
                  <div className="col-xl-4 col-lg-6" onClick={() => history.push("/aventasglobales")}>
                    <WidgetTicket route={"/amazon/consolidatedamazon"} />
                  </div>
                  <div className="col-xl-4 col-lg-6" onClick={() => history.push("/aventasglobales")}>
                    <WidgetPieces route={"/amazon/consolidatedamazon"} color="orange" />
                  </div>
                </div>
                <div className="row ">
                  <div className="col-xl-12 col-lg-12" onClick={() => history.push("/aventasglobales")}>
                    <SellsThisPeriod title={"Ventas Amazon"} route={"/amazon/consolidatedamazon"} color={"rgba(53, 90, 130)"} />
                  </div>
                </div>
                <div className="row ">
                  <div className="col-xl-6 col-lg-6" onClick={() => history.push("/ashipmentstatus")}>
                    <ShipmentStatusPieChart delivered={"Shipped"} route={"/amazon/consolidatedamazon"} />
                  </div>
                  <div className="col-xl-6 col-lg-6" onClick={() => history.push("/aproductos")}>
                    <ProductsPareto route={"/amazon/consolidatedamazon"} />
                  </div>
                </div>
              </div>
            </>
            : view === "meli" ?
              <> */}
                <center><h4>Mercado Libre</h4></center>
                <div className="body flex-grow-1 px-3">
                  <div className="row ">
                    <div className="col-xl-4 col-lg-6" onClick={() => history.push("/mlventasglobales")}>
                      <WidgetSells route={"/meliorders"} />
                    </div>
                    <div className="col-xl-4 col-lg-6" onClick={() => history.push("/mlventasglobales")}>
                      <WidgetTicket route={"/meliorders"} />
                    </div>
                    <div className="col-xl-4 col-lg-6" onClick={() => history.push("/mlclientesrecurrencia")}>
                      <WidgetPieces route={"/meliorders"} color="orange" />
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-xl-12 col-lg-12" onClick={() => history.push("/mlventasglobales")}>
                      <SellsThisPeriod title={"Ventas Mercado Libre"} route={"/meliorders"} color={"rgba(214, 156, 30, .5)"} />
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-xl-6 col-lg-6" onClick={() => history.push("/mlshipmentstatus")}>
                      <ShipmentStatusPieChart delivered={"delivered"} route={"/meliorders"} />
                    </div>
                    <div className="col-xl-6 col-lg-6" onClick={() => history.push("/mlproductos")}>
                      <ProductsPareto route={"/meliorders"} />
                    </div>
                  </div>
                </div>
              {/* </>
              :
              <>
                <center><h4>Todos</h4></center>
                <div className="body flex-grow-1 px-3">
                  <div className="row ">
                    <div className="col-xl-4 col-lg-6">
                      <WidgetSellsAll db1={"/meli/products"} db2={"/amazon/consolidatedamazon"} />
                    </div>
                    <div className="col-xl-4 col-lg-6">
                      <WidgetTicketAll db1={"/meli/products"} db2={"/amazon/consolidatedamazon"} />
                    </div>
                    <div className="col-xl-4 col-lg-6">
                      <WidgetPiecesAll db1={"/meli/products"} db2={"/amazon/consolidatedamazon"} color="orange" />
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-xl-12 col-lg-12">
                      <SellsThisPeriodAll db1={"/meli/products"} db2={"/amazon/consolidatedamazon"} />
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-xl-6 col-lg-6">
                      <SellsPieAll db1={"/meli/products"} db2={"/amazon/consolidatedamazon"} />
                    </div>
                    <div className="col-xl-6 col-lg-6">
                      <ProductsParetoAll db1={"/meli/products"} db2={"/amazon/consolidatedamazon"} />
                    </div>
                  </div>
                </div>
              </>
        } */}
        <AppFooter />
      </div>
    </div>
  );
};

export default Home;