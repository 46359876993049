import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../Contexts/Auth";
import { Button } from "react-bootstrap";
import { CTable, CTableHead, CTableHeaderCell, CTableRow, CTableBody, CTableDataCell, CTooltip, } from "@coreui/react";
import axios from "axios";
import { jsPDF } from "jspdf";
import exportFromJSON from "export-from-json";
import "jspdf-autotable";
import img from "../../Contexts/ImgContext";

const CustomersList = (props) => {
  const { sDate, eDate, authToken, currentUser } = useContext(AuthContext);
  const [order, setOrder] = useState("compras");
  const [direction, setDirection] = useState("desc");
  const [result, setResult] = useState([]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API}${props.route}`,
        { id: currentUser.uid },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((data) => {
        const info = data.data;
        const dat = info.map((x) => new Date(x.date_created.replace('Z', '')).valueOf());
        const data1 = [];
        for (var i = 0; i < info.length; i++) {
          if (
            dat[i] >= sDate &&
            dat[i] <= eDate &&
            info[i].status !== "cancelled"
          ) {
            data1.push({
              cliente: info[i].buyer_nickname,
              product: info[i].name,
              quantity: info[i].quantity,
              mlm: info[i].mlm,
              unit_price: info[i].unit_price,
              date: info[i].date_created.split("T")[0],
              total: parseInt(info[i].total_amount),
            });
          }
        }

        function findOcc(arr, key) {
          let arr2 = [];
          arr.forEach((x) => {
            if (
              arr2.some((val) => {
                return val[key] === x[key];
              })
            ) {
              arr2.forEach((k) => {
                if (k[key] === x[key]) {
                  k["compras"]++;
                }
              });
            } else {
              let a = {};
              a[key] = x[key];
              a["compras"] = 1;
              arr2.push(a);
            }
          });

          return arr2;
        }
        let key = "cliente";
        setResult(findOcc(data1, key));
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, [sDate, eDate, authToken, currentUser, props.route]);

  const sortJSON = (json, key, orden) => {
    // eslint-disable-next-line
    return json.sort(function (a, b) {
      var x = a[key],
        y = b[key];

      if (orden === "asc") {
        return x < y ? -1 : x > y ? 1 : 0;
      }

      if (orden === "desc") {
        return x > y ? -1 : x < y ? 1 : 0;
      }
    });
  };

  sortJSON(result, order, direction);

  /// DESDE AQUI EMPIEZA LOS REPORTES PDF

  const downloadPdf = () => {
    const doc = new jsPDF();
    const text = "Recurrencia de clientes";
    doc.autoTable({
      margin: { top: 50 },
    });
    doc.text(text, 20, 30);
    doc.addImage(img, "JPEG", 140, 15, 45, 15);
    doc.autoTable({ html: "#table" });
    doc.save("RecurrenciaDeClientes.pdf");
  };

  const xls1 = () => {
    const data = result;
    const fileName = "RecurrenciaDeClientes";
    const exportType = "csv";

    exportFromJSON({ data, fileName, exportType });
  };
  /// AQUI TERMINA LOS REPORTES PDF

  return (
    <div className="body flex-grow-1 px-3">
      {/* ///AQUI COMIENZAN LOS REPORTES PDF */}
      <CTooltip
        content="Descarga la información en formato pdf"
        placement="top"
      >
        <Button
          className="download-occurrence"
          variant="outline-danger rounded-circle"
          onClick={downloadPdf}
        >
          <i className="fas fa-file-pdf"></i>
        </Button>
      </CTooltip>
      <CTooltip
        content="Descarga la información en formato csv"
        placement="top"
      >
        <Button
          variant="outline-success rounded-circle"
          onClick={xls1}
          className="download-occurrence"
        >
          {" "}
          <i className="fas fa-file-csv"></i>
        </Button>
      </CTooltip>
      {/* ///AQUI TERMINA LOS REPORTES PDF */}
      <CTable id="table" className="table">
        <CTableHead className="thead">
          <CTableRow>
            <CTableHeaderCell
              className="th"
              onClick={() => {
                if (direction === "asc") {
                  setDirection("desc");
                } else {
                  setDirection("asc");
                }
                setOrder("cliente");
              }}
              scope="col"
            >
              Cliente <i className="sort fas fa-sort"></i>
            </CTableHeaderCell>
            <CTableHeaderCell
              className="th"
              onClick={() => {
                if (direction === "asc") {
                  setDirection("desc");
                } else {
                  setDirection("asc");
                }
                setOrder("compras");
              }}
              scope="col"
            >
              Compras <i className="sort fas fa-sort"></i>
            </CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {result.map((item, index) => (
            <CTableRow color="light">
              <CTableDataCell
                scope="row"
                className="td-product clientes"
              >
                {item.cliente}
              </CTableDataCell>
              <CTableDataCell className="td clientes">
                {item.compras}
              </CTableDataCell>
            </CTableRow>
          ))}
        </CTableBody>
      </CTable>
    </div>
  );
};

export default CustomersList;
