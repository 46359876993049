import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../Contexts/Auth";
import axios from "axios";
import "jspdf-autotable";
import '../../index.css'

const WidgetCustomerReturn = (props) => {
  const { sDate, eDate, authToken, currentUser } = useContext(AuthContext);
  const [result, setResult] = useState([]);
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API}${props.route}`,
        { id: currentUser.uid },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((data) => {
        const info = data.data;
        const dat = info.map((x) => new Date(x.date_created.replace('Z', '')).valueOf());
        const data1 = [];
        for (var i = 0; i < info.length; i++) {
          if (
            dat[i] >= sDate &&
            dat[i] <= eDate &&
            info[i].status !== "cancelled"
          ) {
            data1.push({
              cliente: info[i].buyer_nickname,
              product: info[i].name,
              quantity: info[i].quantity,
              mlm: info[i].mlm,
              unit_price: info[i].unit_price,
              date: info[i].date_created.split("T")[0],
              total: parseInt(info[i].total_amount),
            });
          }
        }

        function findOcc(arr, key) {
          let arr2 = [];
          arr.forEach((x) => {
            if (
              arr2.some((val) => {
                return val[key] === x[key];
              })
            ) {
              arr2.forEach((k) => {
                if (k[key] === x[key]) {
                  k["compras"]++;
                }
              });
            } else {
              let a = {};
              a[key] = x[key];
              a["compras"] = 1;
              arr2.push(a);
            }
          });

          return arr2;
        }
        let key = "cliente";
        setResult(findOcc(data1, key));
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, [sDate, eDate, authToken, currentUser, props.route]);

  useEffect(() => {
    const filter = result.filter((x) => x.compras > 1);

    const returnClient = Math.round(
      parseInt(filter.length * 100) / parseInt(result.length)
    );
    setPercent(returnClient);
  }, [result]);

  return (
    <>
      <div>
        <a href="/productoslist">
          <div className="card l-bg-blue-dark">
            <div className="card-statistic-3 p-4">
              <div className="card-icon card-icon-large">
                <i class="far fa-grin-wink"></i>
              </div>
              <div className="mb-4">
                <h5 className="card-title mb-0">
                  Clientes recurrentes
                </h5>
                <div className="space"></div>
              </div>
              <div className="row align-items-center mb-2 d-flex">
                <div className="col-6">
                  <h4 className="d-flex align-items-center mb-0">
                    {`${isNaN(percent) || !isFinite(percent) ? ("0") : (percent)}%`}
                  </h4>
                </div>
              </div>
              <div
                className="progress mt-1 "
                data-height={8}
                style={{ height: "8px" }}
              >
                <div
                  className="progress-bar l-bg-cyan"
                  role="progressbar"
                  data-width="25%"
                  aria-valuenow={25}
                  aria-valuemin={50}
                  aria-valuemax={150}
                  style={{
                    width: `${isNaN(percent) ||
                      !isFinite(percent)
                      ? "0"
                      : percent
                      }%`,
                  }}
                />
              </div>
            </div>
          </div>
        </a>
      </div>
    </>
  );
};

export default WidgetCustomerReturn;
