/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext } from "react";
import { withRouter, Redirect } from "react-router";
import fire from "../Contexts/fire";
import { AuthContext } from "../Contexts/Auth";
import { Link } from "react-router-dom";
import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CForm,
    CFormInput,
    CInputGroup,
    CInputGroupText,
    CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilUser } from "@coreui/icons";
const ForgotPassword = ({ history }) => {
    const handleRegister = useCallback(
        async (event) => {
            event.preventDefault();
            const { email } = event.target.elements;
            try {
                await fire
                    .auth()
                    .sendPasswordResetEmail(email.value);
                alert('Se envio un correo para restaurar tu contraseña')
            } catch (error) {
                alert(error);
            }
        },
        [history]
    );

    const { currentUser } = useContext(AuthContext);

    if (currentUser) {
        return <Redirect to="/" />;
    }

    return (
        <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
            <CContainer>
                <CRow className="justify-content-center">
                    <CCol md={8}>
                        <CCardGroup>
                            <CCard className="p-4">
                                <CCardBody>
                                    <CForm onSubmit={handleRegister}>
                                        <h1>Olvide mi contraseña</h1>
                                        <p className="text-medium-emphasis">
                                            Restablece tu contraseña
                                        </p>
                                        <CInputGroup className="mb-3">
                                            <CInputGroupText>
                                                <CIcon icon={cilUser} />
                                            </CInputGroupText>
                                            <CFormInput
                                                placeholder="Email"
                                                name="email"
                                                type="email"
                                            />
                                        </CInputGroup>
                                        <CCol xs={6}>
                                            <CButton type="submit" color="primary" className="px-6 olvido">
                                                Reestablecer contraseña
                                            </CButton>
                                        </CCol>
                                    </CForm>
                                </CCardBody>
                            </CCard>
                            <CCard
                                className="text-white bg-primary py-5"
                                style={{ width: "44%" }}
                            >
                                <CCardBody className="text-center">
                                    <div>
                                        <h2>¿Ya tienes cuenta?</h2>
                                        <p>
                                            ¡Inicia sesión!
                                        </p>
                                        <Link to="/login">
                                            <CButton
                                                color="primary"
                                                className="mt-3"
                                                active
                                                tabIndex={-1}
                                            >
                                                Ir a inicio de sesión
                                            </CButton>
                                        </Link>
                                    </div>
                                </CCardBody>
                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    );
};

export default withRouter(ForgotPassword);