import React, { useEffect, useLayoutEffect, useState, useContext } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { AuthContext } from "../../Contexts/Auth";
import axios from "axios";

const ShipmentStatusPieChart = (props) => {
  const { sDate, eDate, authToken, currentUser } = useContext(AuthContext);
  const [delivered, setDelivered] = useState();
  const [ready_to_ship, setReady_to_ship] = useState();
  const [cancelled, setCancelled] = useState();
  const [not_delivered, setNot_delivered] = useState();

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API}${props.route}`,
        { id: currentUser.uid },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((data) => {
        const info = data.data;
        const dat = info.map((x) => new Date(x.date_created.replace('Z', '')).valueOf());
        const data1 = [];
        for (var i = 0; i < info.length; i++) {
          if (dat[i] >= sDate && dat[i] <= eDate) {
            data1.push({
              shipment_status: info[i].shipment_status,
            });
          }
        }
        setDelivered(
          data1.filter(function (value) {
            return value.shipment_status === props.delivered;
          }).length
        );
        setReady_to_ship(
          data1.filter(function (value) {
            return value.shipment_status === "ready_to_ship";
          }).length
        );
        setCancelled(
          data1.filter(function (value) {
            return value.shipment_status === "cancelled";
          }).length
        );
        setNot_delivered(
          data1.filter(function (value) {
            return value.shipment_status === "not_delivered";
          }).length
        );
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, [sDate, eDate, authToken, currentUser, props.route]);

  useLayoutEffect(() => {
    /* Chart code */
    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    let root = am5.Root.new("ShipmentPieMeli");

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
      })
    );

    // Create series
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "value",
        categoryField: "category",
      })
    );
    series.labels.template.setAll({
      maxWidth: 110,
      oversizedBehavior: "wrap", // to truncate labels, use "truncate"
    });
    // Set data
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
    series.data.setAll([
      {
        category: `${delivered} entregados`,
        value: delivered,
      },
      {
        category: `${not_delivered} no entregados`,
        value: not_delivered,
      },
      {
        category: `${ready_to_ship} para entregar`,
        value: ready_to_ship,
      },
      {
        category: `${cancelled} cancelados`,
        value: cancelled,
      },
    ]);

    // Create legend
    // https://www.amcharts.com/docs/v5/charts/percent-charts/legend-percent-series/
    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
        marginTop: 15,
        marginBottom: 15,
      })
    );

    legend.data.setAll(series.dataItems);

    // Play initial series animation
    // https://www.amcharts.com/docs/v5/concepts/animations/#Animation_of_series
    series.appear(1000, 100);

    root.current = root;

    return () => {
      root.dispose();
    };
  }, [delivered, cancelled, ready_to_ship, not_delivered]);

  return (
    <div>
      <div id="ShipmentPieMeli" style={{ width: "100%", height: "400px" }}></div>
    </div>
  );
};

export default ShipmentStatusPieChart;
