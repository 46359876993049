import React, { useEffect, useState, useContext, useLayoutEffect } from "react";
import axios from "axios";
import { AuthContext } from "../../Contexts/Auth";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

function ProductsPareto(props) {
  const [datas, setDatas] = useState([]);
  const { sDate, eDate, authToken, currentUser } = useContext(AuthContext);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API}${props.route}`,
        { id: currentUser.uid },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((data) => {
        const info = data.data;
        const dat = info.map((x) => new Date(x.date_created.replace('Z', '')).valueOf());
        const data1 = [];
        for (var i = 0; i < info.length; i++) {
          if (
            dat[i] >= sDate &&
            dat[i] <= eDate &&
            info[i].status !== "cancelled"
          ) {
            data1.push({
              product: info[i].name,
              quantity: info[i].quantity,
              mlm: info[i].mlm,
              unit_price: info[i].unit_price,
              date: info[i].date_created.split("T")[0],
              total: parseInt(info[i].total_amount),
            });
          }
        }
        const products = data1.map((x) => x.product);
        const arrayProducts = [...new Set(products)];
        const arraySells = [];
        arrayProducts.forEach((pro) => {
          const filter = data1.filter((x) => x.product === pro);
          const sells = filter.reduce((acc, value) => acc + value.quantity, 0);
          arraySells.push(sells);
        });

        const arraySellsT = [];
        arrayProducts.forEach((pro) => {
          const filter = data1.filter((x) => x.product === pro);
          const sells = filter.reduce((acc, value) => acc + value.total, 0);
          arraySellsT.push(sells);
        });

        const mlm = data1.map((x) => x.mlm);
        const arrayMlm = [...new Set(mlm)];
        const datas34 = [];
        for (var i2 = 0; i2 < arrayProducts.length; i2++) {
          datas34.push({
            producto: arrayProducts[i2],
            cantidad: arraySells[i2],
            mlm: arrayMlm[i2],
            total: arraySellsT[i2],
          });
        }
        setDatas(
          datas34.sort(function (a, b) {
            return b.total - a.total;
          })
        );
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, [sDate, eDate, authToken, currentUser, props.route]);
 
  useLayoutEffect(() => {
    /* Chart code */
    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    let root = am5.Root.new("ParetoMeli");

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        layout: root.verticalLayout,
      })
    );

    // let colors = chart.get("colors");

    let data = datas.map((item) => ({
      country: item.producto,
      visits: item.total,
    }));

    prepareParetoData();

    function prepareParetoData() {
      let total = 0;

      for (var i = 0; i < data.length; i++) {
        let value = data[i].visits;
        total += value;
      }

      let sum = 0;
      for (var i2 = 0; i2 < data.length; i2++) {
        let value = data[i2].visits;
        sum += value;
        data[i2].pareto = (sum / total) * 100;
      }
    }

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "country",
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 30,
        }),
      })
    );

    xAxis.get("renderer").labels.template.setAll({
      paddingTop: 20,
      visible: false,
    });

    xAxis.data.setAll(data);

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );

    let paretoAxisRenderer = am5xy.AxisRendererY.new(root, { opposite: true });
    let paretoAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: paretoAxisRenderer,
        min: 0,
        max: 100,
        strictMinMax: true,
      })
    );

    paretoAxisRenderer.grid.template.set("forceHidden", true);
    paretoAxis.set("numberFormat", "#'%");

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    let series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "visits",
        categoryXField: "country",
      })
    );

    series.columns.template.setAll({
      tooltipText: "{categoryX}: {valueY}",
      tooltipY: 0,
      strokeOpacity: 0,
      cornerRadiusTL: 6,
      cornerRadiusTR: 6,
    });

    series.columns.template.adapters.add("fill", function (fill, target) {
      return chart
        .get("colors")
        .getIndex(series.dataItems.indexOf(target.dataItem));
    });

    // pareto series
    let paretoSeries = chart.series.push(
      am5xy.LineSeries.new(root, {
        xAxis: xAxis,
        yAxis: paretoAxis,
        valueYField: "pareto",
        categoryXField: "country",
        stroke: root.interfaceColors.get("alternativeBackground"),
        maskBullets: false,
      })
    );

    paretoSeries.bullets.push(function () {
      return am5.Bullet.new(root, {
        locationY: 1,
        sprite: am5.Circle.new(root, {
          radius: 5,
          fill: series.get("fill"),
          stroke: root.interfaceColors.get("alternativeBackground"),
        }),
      });
    });

    series.data.setAll(data);
    paretoSeries.data.setAll(data);

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear();
    chart.appear(1000, 100);

    root.current = root;

    return () => {
      root.dispose();
    };
  }, [datas]);

  return (
    <div>
      <div id="ParetoMeli" style={{ width: "100%", height: "400px" }}></div>
    </div>
  );
}

export default ProductsPareto;
