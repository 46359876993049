import React from "react";
import AppHeader from "../Layout/AppHeader";
import AppFooter from "../Layout/AppFooter";
import AppSidebar from "../Layout/AppSidebar";
import { CRow, CCol, CButton } from "@coreui/react";

const Connect = () => {
  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <CRow className="text-center">
            <CCol>
              <h1>Mercado Libre</h1>
              <CButton href="https://auth.mercadolibre.com.mx/authorization?response_type=code&client_id=2438827974518967&redirect_uri=https://app.m3trik.io/success-meli" className="btn btn-info" type="CButton">
              {/* <CButton href="https://auth.mercadolibre.com.mx/authorization?response_type=code&client_id=4900953634195715&redirect_uri=https://app.m3trik.io/success-meli" className="btn btn-info" type="CButton"> */}
                Conectar
              </CButton>
            </CCol>
            <CCol>
              <h1>Amazon</h1>
              <button className="btn btn-warning" type="button">
                Conectar
              </button>
            </CCol>
          </CRow>
        </div>
        <AppFooter />
      </div>
    </div>
  );
};

export default Connect;
