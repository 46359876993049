import React from "react";
import CIcon from "@coreui/icons-react";
import {
  cilBarChart,
  cilHappy,
  // cibAmazon,
  // cilHome,
  cilTruck,
  cilCart,
} from "@coreui/icons";
import { CNavGroup, CNavItem, CImage } from "@coreui/react";
import meli from "../assets/meli.png";

const _nav = [
  {
    component: CNavItem,
    name: "Home",
    to: "/",
    icon: <CImage className="meliico" fluid width={27} height={27} src={meli} />,
  },
  {
    component: CNavItem,
    name: "Ventas",
    to: "/mlventasglobales",
    icon: <CIcon icon={cilBarChart} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Clientes",
    to: "/mlclientesrecurrencia",
    icon: <CIcon icon={cilHappy} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Envios",
    to: "/mlshipmentstatus",
    icon: <CIcon icon={cilTruck} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Productos",
    to: "/mlproductos",
    icon: <CIcon icon={cilCart} customClassName="nav-icon" />,
  },
  // {
  //   component: CNavGroup,
  //   name: "Mercado Libre",
  //   to: "/base",
  //   icon: (
  //     <CImage className="meliico" fluid width={27} height={27} src={meli} />
  //   ),
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: "Ventas",
  //       to: "/mlventasglobales",
  //       icon: <CIcon icon={cilBarChart} customClassName="nav-icon" />,
  //     },
  //     {
  //       component: CNavItem,
  //       name: "Clientes",
  //       to: "/mlclientesrecurrencia",
  //       icon: <CIcon icon={cilHappy} customClassName="nav-icon" />,
  //     },
  //     {
  //       component: CNavItem,
  //       name: "Envios",
  //       to: "/mlshipmentstatus",
  //       icon: <CIcon icon={cilTruck} customClassName="nav-icon" />,
  //     },
  //     {
  //       component: CNavItem,
  //       name: "Productos",
  //       to: "/mlproductos",
  //       icon: <CIcon icon={cilCart} customClassName="nav-icon" />,
  //     },
  //     // {
  //     //   component: CNavItem,
  //     //   name: "Rentabilidad",
  //     //   to: "/mlrentabilidad",
  //     //   icon: <CIcon icon={cilBalanceScale} customClassName="nav-icon" />,
  //     // },
  //   ],
  // },
  // {
  //   component: CNavGroup,
  //   name: "Amazon",
  //   to: "/base",
  //   icon: <CIcon icon={cibAmazon} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: "Ventas",
  //       to: "/aventasglobales",
  //       icon: <CIcon icon={cilBarChart} customClassName="nav-icon" />,
  //     },

  //     // {
  //     //   component: CNavItem,
  //     //   name: "Clientes",
  //     //   to: "/aclientesrecurrencia",
  //     //   icon: <CIcon icon={cilHappy} customClassName="nav-icon" />,
  //     // },
  //     {
  //       component: CNavItem,
  //       name: "Envios",
  //       to: "/ashipmentstatus",
  //       icon: <CIcon icon={cilTruck} customClassName="nav-icon" />,
  //     },
  //     {
  //       component: CNavItem,
  //       name: "Productos",
  //       to: "/aproductos",
  //       icon: <CIcon icon={cilCart} customClassName="nav-icon" />,
  //     },
  //     // {
  //     //   component: CNavItem,
  //     //   name: "Rentabilidad",
  //     //   to: "/arentabilidad",
  //     //   icon: <CIcon icon={cilBalanceScale} customClassName="nav-icon" />,
  //     // },
  //   ],
  // },
];

export default _nav;
