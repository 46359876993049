import React from "react";
import AppHeader from "../../Layout/AppHeader";
import AppFooter from "../../Layout/AppFooter";
import AppSidebar from "../../Layout/AppSidebar";
import { Row, Col } from "react-bootstrap";
import ProductsPareto from "../../components/charts/ProductsPareto";
import ProductsDonutData from "../../components/charts/ProductsDonutData";
import ProductsList from "../../components/charts/ProductsList";

const Products = () => {

  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <Row>
            <Col>
              <h1 className="title">Diagrama pareto 80/20 %</h1>
              <ProductsPareto route={"/meliorders"} />
            </Col>
            <Col>
              <h1 className="title">Porcentaje de ventas</h1>
              <ProductsDonutData route={"/meliorders"} />
            </Col>
          </Row>
          <ProductsList route={"/meliorders"}/>
        </div>
        <AppFooter />
      </div>
    </div>
  );
};

export default Products;
