import React from "react";
import AppHeader from "../../Layout/AppHeader";
import AppFooter from "../../Layout/AppFooter";
import AppSidebar from "../../Layout/AppSidebar";
import { CContainer } from "@coreui/react";
import "jspdf-autotable";
import SellsThisPeriodList from "../../components/charts/SellsThisPeriodList";
import SellsThisPeriod from "../../components/charts/SellsThisPeriod";
import { Row } from "react-bootstrap";

const Sells = () => {

  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <CContainer>
          <Row>
            <SellsThisPeriod title={"Ventas Mercado Libre"} route={"/meliorders"} color={"rgba(214, 156, 30, .5)"} />
          </Row>
          <Row>
            <SellsThisPeriodList route={"/meliorders"}/>
          </Row>
        </CContainer>
        <AppFooter />
      </div>
    </div>
  );
};

export default Sells;
