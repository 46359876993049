import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../Contexts/Auth";
import axios from "axios";
import "./Widgets.css";

const WidgetTicket = (props) => {
  const { sDate, eDate, authToken, currentUser } = useContext(AuthContext);
  const [averageTicketThisPeriod, setAverageTicketThisPeriod] = useState([]);
  const [averageTicketLastPeriod, setAverageTicketLastPeriod] = useState([]);
  const [averageTicketThisVsLast, setAverageTicketThisVsLast] = useState();

  useEffect(() => {
    setAverageTicketThisPeriod([]);
    setAverageTicketLastPeriod([]);
    setAverageTicketThisVsLast([]);
    // if (`${process.env.REACT_APP_API}${props.route}`.includes("undefined")) {
    //   console.log("Cargando...");
    // } else {
      axios
        .get(
          `${process.env.REACT_APP_API}${props.route}`,
          { id: currentUser.uid },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((data) => {
          const info = data.data;
          const dat = info.map((x) => new Date(x.date_created.replace('Z', '')).valueOf());
          let periodSize = eDate - sDate;
          let latestPeriodRangeStart = new Date(sDate - periodSize).valueOf();
          let latestPeriodRangeEnd = new Date(eDate - periodSize).valueOf();

          const data1 = [];
          for (var i = 0; i < info.length; i++) {
            if (
              dat[i] >= sDate &&
              dat[i] <= eDate &&
              info[i].status !== "cancelled"
            ) {
              data1.push({
                product: info[i].name,
                quantity: info[i].quantity,
                date: info[i].date_created.split("T")[0],
                total: parseInt(info[i].total_amount),
                orders: info[i].shipping_id,
              });
            }
          }
          const products = data1.map((x) => x.product);
          const arrayProducts = [...new Set(products)];
          const arraySells = [];
          arrayProducts.forEach((pro) => {
            const filter = data1.filter((x) => x.product === pro);
            const sells = filter.reduce(
              (acc, value) => acc + value.quantity,
              0
            );
            arraySells.push(sells);
          });

          const arraySellsT = [];
          arrayProducts.forEach((pro) => {
            const filter = data1.filter((x) => x.product === pro);
            const sells = filter.reduce((acc, value) => acc + value.total, 0);
            arraySellsT.push(sells);
          });
          const amountThisPeriod = arraySellsT.reduce((a, b) => a + b, 0);

          const totalTickets = data1.map((x) => x.orders);
          const arrayTickets = [...new Set(totalTickets)];
          setAverageTicketThisPeriod(amountThisPeriod / arrayTickets.length);

          const data2 = [];
          for (var i2 = 0; i2 < info.length; i2++) {
            if (
              dat[i2] >= latestPeriodRangeStart &&
              dat[i2] <= latestPeriodRangeEnd &&
              dat[i2].status !== "cancelled"
            ) {
              data2.push({
                product: info[i2].name,
                quantity: info[i2].quantity,
                date: info[i2].date_created,
                total: parseInt(info[i2].total_amount),
                orders: info[i2].shipping_id,
              });
            }
          }
          const products2 = data2.map((x) => x.product);
          const arrayProducts2 = [...new Set(products2)];
          const arraySells2 = [];
          arrayProducts2.forEach((pro) => {
            const filter = data2.filter((x) => x.product === pro);
            const sells = filter.reduce(
              (acc, value) => acc + value.quantity,
              0
            );
            arraySells2.push(sells);
          });

          const arraySellsT2 = [];
          arrayProducts2.forEach((pro) => {
            const filter2 = data2.filter((x) => x.product === pro);
            const sells2 = filter2.reduce((acc, value) => acc + value.total, 0);
            arraySellsT2.push(sells2);
          });
          const amountLastPeriod = arraySellsT2.reduce((a, b) => a + b, 0);

          const totalTickets2 = data2.map((x) => x.orders);
          const arrayTickets2 = [...new Set(totalTickets2)];
          setAverageTicketLastPeriod(amountLastPeriod / arrayTickets2.length);
        })
        .catch((err) => console.log(err));
    // }
    // eslint-disable-next-line
  }, [sDate, eDate, authToken, currentUser, props.route]);

  useEffect(() => {
    setAverageTicketThisVsLast(
      (
        ((averageTicketThisPeriod - averageTicketLastPeriod) /
          averageTicketLastPeriod) *
        100
      ).toFixed(0)
    );
    // eslint-disable-next-line
  }, [averageTicketLastPeriod]);

  return (
    <div className="card l-bg-green-dark">
      <div className="card-statistic-3 p-4">
        <div className="card-icon card-icon-large">
          <i className="fas fa-chart-line"></i>
        </div>
        <div className="mb-4">
          <h5 className="card-title mb-0">
            Ticket Promedio <br />
            (vs periodo anterior)
          </h5>
        </div>
        <div className="row align-items-center mb-2 d-flex">
          <div className="col-6">
            <h5 className="d-flex align-items-center mb-0">
              {isNaN(averageTicketThisPeriod) ||
                !isFinite(averageTicketThisPeriod)
                ? "$0.00"
                : averageTicketThisPeriod.toLocaleString("sp-ES", {
                  style: "currency",
                  currency: "MXN",
                  minimumFractionDigits: 2,
                })}
            </h5>
          </div>
          <div className="col-6 text-right">
            <span>
              (
              {isNaN(averageTicketThisVsLast) ||
                !isFinite(averageTicketThisVsLast)
                ? "0"
                : averageTicketThisVsLast}{" "}
              %{" "}
              {averageTicketThisVsLast > 0 ? (
                <i className="fa fa-arrow-up" />
              ) : (
                <i className="fa fa-arrow-down" />
              )}
              )
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WidgetTicket;
