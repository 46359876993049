import React from "react";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import {
  cilUser,
  cilAccountLogout,
  cilVector,
} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import fire from "../Contexts/fire";

const AppHeaderDropdown = () => {
  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <CIcon icon={cilUser} className="me-2" size="xl" />
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem href="/connect">
          <CIcon icon={cilVector} className="me-2" />
          Conecta tus cuentas
        </CDropdownItem>
        <CDropdownItem onClick={() => fire.auth().signOut()}>
          {" "}
          <CIcon icon={cilAccountLogout} className="me-2" />
          Log out
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default AppHeaderDropdown;
