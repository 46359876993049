import React, { useEffect, useState } from "react";
import fire from "./fire";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [authToken, setAuthToken] = useState(null);
  const [pending, setPending] = useState(true);
  const [sDate, setSDate] = useState(new Date().setHours(0, 0, 0, 0).valueOf() - 86400000 * 30);
  const [eDate, setEDate] = useState(new Date().setHours(23, 59, 59, 999).valueOf());

  useEffect(() => {
    fire.auth().onAuthStateChanged((user) => {
      setCurrentUser(user);
      setPending(false);
    });
  }, []);

  useEffect(() => {
    fire.auth().onAuthStateChanged(async (user) => {
      const token = user && (await user.getIdToken());
      setAuthToken(token);
    });
  }, [currentUser]);

  const setDates = (dates) => {
    setSDate(new Date(dates[0].startDate).setHours(0, 0, 0, 0).valueOf());
    setEDate(new Date(dates[0].endDate).setHours(23, 59, 59, 999).valueOf());
  };

  const delDates = () => {
    setSDate();
    setEDate("Escoge fecha");
  };

  if (pending) {
    return <>Cargando...</>;
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        sDate,
        eDate,
        authToken,
        setDates,
        delDates,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
