import React, { useEffect, useState, useContext } from "react";
import { CCard, CCardBody, CCardFooter, CCol, CRow, CTooltip, } from "@coreui/react";
import { Button } from "react-bootstrap";
import { CChartLine } from "@coreui/react-chartjs";
import axios from "axios";
import { AuthContext } from "../../Contexts/Auth";
import exportFromJSON from "export-from-json";
import "jspdf-autotable";

const SellsThisPeriod = (props) => {
  const { sDate, eDate, authToken, currentUser } = useContext(AuthContext);
  const [salByDay, setSalByDay] = useState([]);
  const [totalPieces, setTotalPieces] = useState([]);
  const [totalAmount, setTotalAmount] = useState([]);
  const [averageTicket, setAverageTicket] = useState([]);
  const [latestPeriodData, setLatestPeriodData] = useState([]);
  // eslint-disable-next-line
  const [a, setA] = useState([]);
  const datesRange = [];
  const datesRangeLast = [];

  useEffect(() => {

    setSalByDay([]);
    setTotalPieces([]);
    setTotalAmount([]);
    setAverageTicket([]);
    setLatestPeriodData([]);

    axios
      .get(
        `${process.env.REACT_APP_API}${props.route}`,
        { id: currentUser.uid },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((data) => {
        const info = data.data;
        const dat = info.map((x) => new Date(x.date_created.replace('Z', '')).valueOf());

        const data1 = [];
        for (var i = 0; i < info.length; i++) {
          if (
            dat[i] >= sDate &&
            dat[i] <= eDate &&
            info[i].status !== "cancelled"
          ) {
            data1.push({
              orders: info[i].shipping_id,
              product: info[i].name,
              quantity: info[i].quantity,
              mlm: info[i].mlm,
              unit_price: info[i].unit_price,
              date: info[i].date_created.split("T")[0],
              total: parseInt(info[i].total_amount),
            });
          }
        }
        const products = data1.map((x) => x.product);
        const arrayProducts = [...new Set(products)];
        const arraySells = [];
        arrayProducts.forEach((pro) => {
          const filter = data1.filter((x) => x.product === pro);
          const sells = filter.reduce((acc, value) => acc + value.quantity, 0);
          arraySells.push(sells);
        });
        const totalPieces = arraySells.reduce((a, b) => a + b, 0);
        setTotalPieces(totalPieces);

        const arraySellsT = [];
        arrayProducts.forEach((pro) => {
          const filter = data1.filter((x) => x.product === pro);
          const sells = filter.reduce((acc, value) => acc + value.total, 0);
          arraySellsT.push(sells);
        });
        const totalAmount = arraySellsT.reduce((a, b) => a + b, 0);
        setTotalAmount(totalAmount);

        /// constante de ventas por dia
        const days = data1.map((x) => x.date);
        const arrayDates = [...new Set(days)];
        const arraySellsTByDay = [];
        arrayDates.forEach((dat) => {
          const filter = data1.filter((x) => x.date === dat);
          const sells = filter.reduce((acc, value) => acc + value.total, 0);
          arraySellsTByDay.push(sells);
        });

        const salesByDay = [];

        for (var i2 = 0; i2 < arrayDates.length; i2++) {
          salesByDay.push({
            fecha: arrayDates[i2],
            total: arraySellsTByDay[i2],
          });
        }

        const date = new Date(sDate)

        while (date <= new Date(eDate)) {
          datesRange.push(date.toISOString().split('T')[0]);
          date.setDate(date.getDate() + 1);
        }

        let tieneRangeNoTieneFirst = datesRange.filter(x => !arrayDates.includes(x));
        for (let i in tieneRangeNoTieneFirst) {
          salesByDay.push({
            fecha: tieneRangeNoTieneFirst[i],
            total: 0
          })
        }

        setSalByDay(salesByDay);

        const totalTickets = data1.map((x) => x.orders);
        const arrayTickets = [...new Set(totalTickets)];
        setAverageTicket(totalAmount / arrayTickets.length);        

        let periodSize = eDate - sDate;
        let latestPeriodRangeStart = new Date(sDate - periodSize).valueOf();
        let latestPeriodRangeEnd = new Date(eDate - periodSize).valueOf();

        const latestPeriodRange = [];
        // eslint-disable-next-line
        for (var i = 0; i < info.length; i++) {
          if (
            dat[i] >= latestPeriodRangeStart &&
            dat[i] <= latestPeriodRangeEnd &&
            info[i].status !== "cancelled"
          ) {
            latestPeriodRange.push({
              orders: info[i].shipping_id,
              product: info[i].name,
              quantity: info[i].quantity,
              date: info[i].date_created.split("T")[0],
              total: parseInt(info[i].total_amount),
            });
          }
        }
        const days2 = latestPeriodRange.map((x) => x.date);
        const arrayDates2 = [...new Set(days2)];
        const arraySells3 = [];
        arrayDates2.forEach((dat) => {
          const filter = latestPeriodRange.filter((x) => x.date === dat);
          const sells = filter.reduce((acc, value) => acc + value.total, 0);
          arraySells3.push(sells);
        });

        const datas = [];

        for (var i4 = 0; i4 < arrayDates2.length; i4++) {
          datas.push({
            fecha: arrayDates2[i4],
            total: arraySells3[i4],
          });
        }

        const date2 = new Date(latestPeriodRangeStart)

        while (date2 <= new Date(latestPeriodRangeEnd)) {
          datesRangeLast.push(date2.toISOString().split('T')[0]);
          date2.setDate(date2.getDate() + 1);
        }

        let tieneRangeLastNoTieneLast = datesRangeLast.filter(x => !arrayDates2.includes(x));
        for (let i in tieneRangeLastNoTieneLast) {
          datas.push({
            fecha: tieneRangeLastNoTieneLast[i],
            total: 0
          })
        }

        setLatestPeriodData(datas);

        setA(Math.random());

      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, [sDate, eDate, authToken, currentUser, props.route]);

  const sellsInfo = [
    {
      title: "Total Venta",
      value: totalAmount.toLocaleString("sp-ES", {
        style: "currency",
        currency: "MXN",
        minimumFractionDigits: 2,
      }),
    },
    {
      title: "Total Piezas Vendidas",
      value: totalPieces,
    },
    {
      title: "Ticket Promedio",
      value: isNaN(averageTicket) || !isFinite(averageTicket) ? ("$0.00") : (averageTicket.toLocaleString("sp-ES", {
        style: "currency",
        currency: "MXN",
        minimumFractionDigits: 2,
      })),
    },
  ];

  const xls = () => {
    const data = salByDay;
    const fileName = "VentasGlobales";
    const exportType = "csv";

    exportFromJSON({ data, fileName, exportType });
  };

  const sortJSON = (json, key, orden) => {
    // eslint-disable-next-line
    return json.sort(function (a, b) {
      var x = a[key],
        y = b[key];

      if (orden === "asc") {
        return x < y ? -1 : x > y ? 1 : 0;
      }

      if (orden === "desc") {
        return x > y ? -1 : x < y ? 1 : 0;
      }
    });
  };

  sortJSON(salByDay, "fecha", "asc");
  sortJSON(latestPeriodData, "fecha", "asc");

  return (
    <CCard className="mb-4">
      <CCardBody>
        <CRow>
          <CCol sm={5}>
            <h4 id="traffic" className="card-title mb-0">
              {props.title}
            </h4>
          </CCol>
          <CCol sm={7} className="d-none d-md-block">
            {/* ///DESDE AQUI EMPIEZA LOS REPORTES PDF */}
            <div className="downloadGraphic">
              <CTooltip
                content="Descarga la información en formato csv"
                placement="top"
              >
                <Button
                  variant="outline-success rounded-circle"
                  onClick={xls}
                >
                  {" "}
                  <i className="fas fa-file-csv"></i>
                </Button>
              </CTooltip>
            </div>
            {/* ///AQUI TERMINA LOS REPORTES PDF */}
          </CCol>
        </CRow>
        <CChartLine
          style={{ height: "300px", marginTop: "40px" }}
          data={{
            labels: salByDay.map((value) => value.fecha),
            datasets: [
              {
                label: "Ventas del dia",
                backgroundColor: `${props.color}`,
                borderColor: `${props.color}`,
                pointHoverBackgroundColor: `${props.color}`,
                borderWidth: 3,
                data: salByDay.map((value) => value.total),
              },
              {
                label: "Periodo anterior",
                backgroundColor: "rgba(87, 234, 77, .5)",
                borderColor: "rgba(87, 234, 77, .5)",
                pointHoverBackgroundColor: "rgba(87, 234, 77, .5)",
                borderWidth: 2,
                data: latestPeriodData.map((value) => value.total),
              },
            ],
          }}
          options={{
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: true,
              },
            },
            scales: {
              x: {
                grid: {
                  drawOnChartArea: false,
                },
              },
              y: {
                ticks: {
                  beginAtZero: true,
                  maxTicksLimit: 5,
                  stepSize: Math.ceil(250 / 5),
                  max: 250,
                },
              },
            },
            elements: {
              line: {
                tension: 0.4,
              },
              point: {
                radius: 0,
                hitRadius: 10,
                hoverRadius: 4,
                hoverBorderWidth: 3,
              },
            },
          }}
        />
      </CCardBody>
      <CCardFooter>
        <CRow
          xs={{ cols: 1 }}
          md={{ cols: 3 }}
          className="text-center"
        >
          {sellsInfo.map((item, index) => (
            <CCol className="mb-sm-2 mb-0" key={index}>
              <div className="text-medium-emphasis">
                {item.title}
              </div>
              <strong>
                {item.value}
              </strong>
            </CCol>
          ))}
        </CRow>
      </CCardFooter>
    </CCard>
  );
};

export default SellsThisPeriod;
