import React, { useState, useContext } from "react";
import { CButton, CModal } from "@coreui/react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker, DateRange } from "react-date-range";
import * as locales from "react-date-range/dist/locale";
import { addDays } from "date-fns";
import { AuthContext } from "../../Contexts/Auth";
import useWindowWidthAndHeight from "../useWindowWidthAndHeight/useWindowWidthAndHeight";

function Datepicker() {
  const [visible, setVisible] = useState(false);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);
  const { setDates } = useContext(AuthContext);
  const [width] = useWindowWidthAndHeight();

  return (
    <>
      {
        width > 1000 ?
          <>
            <CButton onClick={() => setVisible(!visible)}>Fechas</CButton>
            <CModal visible={visible} onClose={() => setVisible(false)}>
              <DateRangePicker
                onChange={(item) => {
                  setDates([item.selection]);
                  setState([item.selection]);
                }}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={1}
                ranges={state}
                scroll={{ enabled: true }}
                direction="vertical"
                locale={locales["es"]}
              />
              <CButton
                onClick={() => setVisible(false)}
                component="a"
                color="light"
                href="#"
                role="button"
              >
                Aplicar
              </CButton>
            </CModal>
          </>
          :
          <>
            <CButton onClick={() => setVisible(!visible)}>Fechas</CButton>
            <CModal visible={visible} onClose={() => setVisible(false)}>
              <DateRange
                editableDateInputs={true}
                showSelectionPreview={true}
                onChange={(item) => {
                  setDates([item.selection]);
                  setState([item.selection]);
                }}
                moveRangeOnFirstSelection={false}
                ranges={state}
                direction="vertical"
                locale={locales["es"]}
              />
              <CButton
                onClick={() => setVisible(false)}
                component="a"
                color="light"
                href="#"
                role="button"
              >
                Aplicar
              </CButton>
            </CModal>
          </>
      }
    </>
  );
}

export default Datepicker;
