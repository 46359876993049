import React, { useEffect, useState, useContext } from "react";
import {
  CTableDataCell,
  CTableBody,
  CTable,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import axios from "axios";
import { AuthContext } from "../../Contexts/Auth";
import "jspdf-autotable";

const SellsThisPeriodList = (props) => {
  const { sDate, eDate, authToken, currentUser } = useContext(AuthContext);
  const [salByDay, setSalByDay] = useState([]);
  const [order, setOrder] = useState("producto");
  const [direction, setDirection] = useState("asc");

  useEffect(() => {
    setSalByDay([]);
    axios
      .get(
        `${process.env.REACT_APP_API}${props.route}`,
        { id: currentUser.uid },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((data) => {
        const info = data.data;
        const dat = info.map((x) => new Date(x.date_created.replace('Z', '')));

        const data1 = [];
        for (var i = 0; i < info.length; i++) {
          if (
            dat[i] >= sDate &&
            dat[i] <= eDate &&
            info[i].status !== "cancelled"
          ) {
            data1.push({
              product: info[i].name,
              quantity: info[i].quantity,
              mlm: info[i].mlm,
              unit_price: info[i].unit_price,
              date: info[i].date_created.split("T")[0],
              total: parseInt(info[i].total_amount),
            });
          }
        }
        const days = data1.map((x) => x.date);
        const arrayDates = [...new Set(days)];
        const arraySellsTByDay = [];
        arrayDates.forEach((dat) => {
          const filter = data1.filter((x) => x.date === dat);
          const sells = filter.reduce((acc, value) => acc + value.total, 0);
          arraySellsTByDay.push(sells);
        });
        const salesByDay = [];
        for (var i2 = 0; i2 < arrayDates.length; i2++) {
          salesByDay.push({
            fecha: arrayDates[i2],
            total: arraySellsTByDay[i2],
          });
        }
        setSalByDay(salesByDay);
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, [sDate, eDate, authToken, currentUser, props.route]);

  const sortJSON = (json, key, orden) => {
    // eslint-disable-next-line
    return json.sort(function (a, b) {
      var x = a[key],
        y = b[key];

      if (orden === "asc") {
        return x < y ? -1 : x > y ? 1 : 0;
      }

      if (orden === "desc") {
        return x > y ? -1 : x < y ? 1 : 0;
      }
    });
  };

  sortJSON(salByDay, order, direction);


  return (
    <div className="max">
      <CTable id="table" className="table">
        <CTableHead className="thead">
          <CTableRow>
            <CTableHeaderCell
              className="th"
              onClick={() => {
                if (direction === "asc") {
                  setDirection("desc");
                } else {
                  setDirection("asc");
                }
                setOrder("fecha");
              }}
              scope="col"
            >
              Fecha <i className="sort fas fa-sort"></i>
            </CTableHeaderCell>
            <CTableHeaderCell
              className="th"
              onClick={() => {
                if (direction === "asc") {
                  setDirection("desc");
                } else {
                  setDirection("asc");
                }
                setOrder("total");
              }}
              scope="col"
            >
              Total <i className="sort fas fa-sort"></i>
            </CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody className="max">
          {salByDay.map((item, index) => (
            <CTableRow color="light">
              <CTableDataCell className="td">
                {item.fecha}
              </CTableDataCell>
              <CTableDataCell className="amount td">
                {item.total.toLocaleString("sp-ES", {
                  style: "currency",
                  currency: "MXN",
                  minimumFractionDigits: 2,
                })}
              </CTableDataCell>
            </CTableRow>
          ))}
        </CTableBody>
      </CTable>
    </div>

  );
};

export default SellsThisPeriodList;
