import React from "react";
import AppHeader from "../../Layout/AppHeader";
import AppFooter from "../../Layout/AppFooter";
import AppSidebar from "../../Layout/AppSidebar"
import { CContainer, } from "@coreui/react";
import { Row, Col, } from 'react-bootstrap';
import CustomersList from '../../components/charts/CustomersList'
import WidgetCustomerReturn from "../../components/charts/WidgetCustomerReturn";
import WidgetPieces from "../../components/charts/WidgetPieces";

const Customers = () => {

  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <CContainer>
          <Row>
            <Col lg="6">
              <WidgetPieces route={"/meliorders"} color="red" />
            </Col>
            <Col lg="6">
              <WidgetCustomerReturn route={"/meliorders"} />
            </Col>
          </Row>
          <CustomersList route={"/meliorders"} />
        </CContainer>
        <AppFooter />
      </div>
    </div>
  );
};

export default Customers;
