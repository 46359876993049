import React, { useEffect, useLayoutEffect, useState, useContext } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { AuthContext } from "../../Contexts/Auth";
import axios from "axios";

function ShipmentStatusPieChartTrackingMethod(props) {
  const { sDate, eDate, authToken, currentUser } = useContext(AuthContext);
  const [result, setResult] = useState([]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API}${props.route}`,
        { id: currentUser.uid },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((data) => {
        const info = data.data;
        const dat = info.map((x) => new Date(x.date_created.replace('Z', '')).valueOf());
        const data1 = [];
        for (var i = 0; i < info.length; i++) {
          if (
            dat[i] >= sDate &&
            dat[i] <= eDate &&
            info[i].status !== "cancelled"
          ) {
            data1.push({
              tracking_method: info[i].tracking_method,
            });
          }
        }
        function findOcc(arr, key) {
          let arr2 = [];
          arr.forEach((x) => {
            if (
              arr2.some((val) => {
                return val[key] === x[key];
              })
            ) {
              arr2.forEach((k) => {
                if (k[key] === x[key]) {
                  k["orders"]++;
                }
              });
            } else {
              let a = {};
              a[key] = x[key];
              a["orders"] = 1;
              arr2.push(a);
            }
          });
          return arr2;
        }
        let key = "tracking_method";
        setResult(findOcc(data1, key));
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, [sDate, eDate, authToken, currentUser, props.route]);

  useLayoutEffect(() => {
    /* Chart code */
    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    let root = am5.Root.new("chartdivTrackMeli");

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
      })
    );

    // Create series
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "value",
        categoryField: "category",
      })
    );

    series.labels.template.setAll({
      maxWidth: 110,
      oversizedBehavior: "wrap", // to truncate labels, use "truncate"
    });

    // Set data
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
    series.data.setAll(
      result.map((item) => ({
        category: `${item.orders} pedidos ${item.tracking_method}`,
        value: item.orders,
      }))
    );

    // Create legend
    // https://www.amcharts.com/docs/v5/charts/percent-charts/legend-percent-series/
    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
        marginTop: 15,
        marginBottom: 15,
      })
    );

    legend.data.setAll(series.dataItems);

    // Play initial series animation
    // https://www.amcharts.com/docs/v5/concepts/animations/#Animation_of_series
    series.appear(1000, 100);

    root.current = root;

    return () => {
      root.dispose();
    };
  }, [result]);

  return (
    <div>
      <div id="chartdivTrackMeli" style={{ width: "100%", height: "400px" }}></div>
    </div>
  );
}

export default ShipmentStatusPieChartTrackingMethod;
