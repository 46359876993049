import React, { useEffect, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../../Contexts/Auth";
import AppHeader from "../../Layout/AppHeader"
import AppFooter from "../../Layout/AppFooter"
import { CImage } from "@coreui/react";
import successImage from "../../assets/successImage.png"
// import { useHistory } from "react-router-dom";

function SuccessMeli(props) {
  const { currentUser } = useContext(AuthContext);
  // const history = useHistory();
  // const WEBHOOK = "https://webhook.m3trik.io/UserID/Notificaciones/"

  useEffect(() => {
    axios.post(`${process.env.REACT_APP_API}userLoginMeli`,
      {
        idFireBase: currentUser.uid,
        token_meli: props.location.search.split('=')[1].split('&')[0]
      })
      .then((resDB) => {
        // history.push("/");
      })
      .catch((error) => { console.log(error) })
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <AppHeader />
      <div className="text-center">
        <CImage rounded src={successImage} width="30%" height="30%" />
      </div>
      <div className="successFooter">
        <AppFooter />
      </div>
    </>
  );
}

export default SuccessMeli;
