import React, { useEffect, useState, useContext } from "react";
import { CTableDataCell, CTableBody, CTable, CTableHead, CTableHeaderCell, CTableRow, CTooltip, } from "@coreui/react";
import { Button } from "react-bootstrap";
import axios from "axios";
import { AuthContext } from "../../Contexts/Auth";
import { jsPDF } from "jspdf";
import exportFromJSON from "export-from-json";
import "jspdf-autotable";
import img from "../../Contexts/ImgContext";

const ProductsList = (props) => {
  const { sDate, eDate, authToken, currentUser } = useContext(AuthContext);
  const [datas, setDatas] = useState([]);
  const [order, setOrder] = useState("producto");
  const [direction, setDirection] = useState("asc");

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API}${props.route}`,
        { id: currentUser.uid },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((data) => {
        const info = data.data;
        const dat = info.map((x) => new Date(x.date_created.replace('Z', '')).valueOf());
        const data1 = [];
        for (var i = 0; i < info.length; i++) {
          if (
            dat[i] >= sDate &&
            dat[i] <= eDate &&
            info[i].status !== "cancelled"
          ) {
            data1.push({
              product: info[i].name,
              quantity: info[i].quantity,
              mlm: info[i].mlm,
              unit_price: info[i].unit_price,
              date: info[i].date_created.split("T")[0],
              total: parseInt(info[i].total_amount),
            });
          }
        }

        const products = data1.map((x) => x.product);
        const arrayProducts = [...new Set(products)];
        const arraySells = [];
        arrayProducts.forEach((pro) => {
          const filter = data1.filter((x) => x.product === pro);
          const sells = filter.reduce((acc, value) => acc + value.quantity, 0);
          arraySells.push(sells);
        });

        const arraySellsT = [];
        arrayProducts.forEach((pro) => {
          const filter = data1.filter((x) => x.product === pro);
          const sells = filter.reduce((acc, value) => acc + value.total, 0);
          arraySellsT.push(sells);
        });

        const mlm = data1.map((x) => x.mlm);
        const arrayMlm = [...new Set(mlm)];

        const price = data1.map((x) => x.unit_price);
        const arrayPrice = [...new Set(price)];

        const datas34 = [];
        for (var i2 = 0; i2 < arrayProducts.length; i2++) {
          datas34.push({
            producto: arrayProducts[i2],
            cantidad: arraySells[i2],
            mlm: arrayMlm[i2],
            total: arraySellsT[i2],
            price: arrayPrice[i2],
          });
        }
        setDatas(datas34);
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, [sDate, eDate, authToken, currentUser, props.route]);

  const sortJSON = (json, key, orden) => {
    // eslint-disable-next-line
    return json.sort(function (a, b) {
      var x = a[key],
        y = b[key];

      if (orden === "asc") {
        return x < y ? -1 : x > y ? 1 : 0;
      }

      if (orden === "desc") {
        return x > y ? -1 : x < y ? 1 : 0;
      }
    });
  };

  sortJSON(datas, order, direction);

  /// DESDE AQUI EMPIEZA LOS REPORTES PDF

  const downloadPdf = () => {
    const doc = new jsPDF();
    const text = "Ventas por Producto";
    doc.autoTable({
      margin: { top: 50 },
    });
    doc.text(text, 20, 30);
    doc.addImage(img, "JPEG", 160, 15, 20, 20);
    doc.autoTable({ html: "#table" });
    doc.save("VentasPorProducto.pdf");
  };

  const xls1 = () => {
    const data = datas;
    const fileName = "VentasPorProducto";
    const exportType = "csv";

    exportFromJSON({ data, fileName, exportType });
  };
  /// AQUI TERMINA LOS REPORTES PDF

  return (
    <div>
      {/* ///DESDE AQUI EMPIEZA LOS REPORTES PDF */}
      <div>
        <CTooltip
          content="Descarga la información en formato pdf"
          placement="top"
        >
          <Button
            className="download-sellByProduct"
            variant="outline-danger rounded-circle"
            onClick={downloadPdf}
          >
            <i className="fas fa-file-pdf"></i>
          </Button>
        </CTooltip>
        <CTooltip
          content="Descarga la información en formato csv"
          placement="top"
        >
          <Button
            variant="outline-success rounded-circle"
            onClick={xls1}
            className="download-sellByProduct"
          >
            {" "}
            <i className="fas fa-file-csv"></i>
          </Button>
        </CTooltip>
      </div>
      {/* ///AQUI TERMINA LOS REPORTES PDF */}
      <CTable id="table" className="table">
        <CTableHead className="thead">
          <CTableRow>
            <CTableHeaderCell
              className="th"
              onClick={() => {
                if (direction === "asc") {
                  setDirection("desc");
                } else {
                  setDirection("asc");
                }
                setOrder("producto");
              }}
              scope="col"
            >
              Producto <i className="sort fas fa-sort"></i>
            </CTableHeaderCell>
            <CTableHeaderCell
              className="th"
              onClick={() => {
                if (direction === "asc") {
                  setDirection("desc");
                } else {
                  setDirection("asc");
                }
                setOrder("mlm");
              }}
              scope="col"
            >
              MLM <i className="sort fas fa-sort"></i>
            </CTableHeaderCell>
            <CTableHeaderCell
              className="th"
              onClick={() => {
                if (direction === "asc") {
                  setDirection("desc");
                } else {
                  setDirection("asc");
                }
                setOrder("cantidad");
              }}
              scope="col"
            >
              Cantidad <i className="sort fas fa-sort"></i>
            </CTableHeaderCell>
            <CTableHeaderCell
              className="th"
              onClick={() => {
                if (direction === "asc") {
                  setDirection("desc");
                } else {
                  setDirection("asc");
                }
                setOrder("total");
              }}
              scope="col"
            >
              Total <i className="sort fas fa-sort"></i>
            </CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {datas.map((item, index) => (
            <CTableRow color="light">
              <CTableDataCell scope="row" className="td-product">
                {item.producto}
              </CTableDataCell>
              <CTableDataCell className="td">{item.mlm}</CTableDataCell>
              <CTableDataCell className="td">
                {item.cantidad}
              </CTableDataCell>
              <CTableDataCell className="amount td">
                {item.total.toLocaleString("sp-ES", {
                  style: "currency",
                  currency: "MXN",
                  minimumFractionDigits: 2,
                })}
              </CTableDataCell>
            </CTableRow>
          ))}
        </CTableBody>
      </CTable>
    </div>
  );
};

export default ProductsList
  ;
