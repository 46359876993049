import React, { useEffect, useLayoutEffect, useState, useContext } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { AuthContext } from "../../Contexts/Auth";
import axios from "axios";

const ProductsDonutData = (props) => {
  const { sDate, eDate, authToken, currentUser } = useContext(AuthContext);
  const [thisPeriodData, setThisPrediodData] = useState([]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API}${props.route}`,
        { id: currentUser.uid },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((data) => {
        const info = data.data;
        const dat = info.map((x) => new Date(x.date_created.replace('Z', '')).valueOf());

        const thisPeriodRange = [];
        for (var i = 0; i < info.length; i++) {
          if (dat[i] >= sDate && dat[i] <= eDate) {
            thisPeriodRange.push({
              product: info[i].name,
              quantity: info[i].quantity,
              date: info[i].date_created.split("T")[0],
            });
          }
        }
        const products = thisPeriodRange.map((x) => x.product);
        const arrayProducts = [...new Set(products)];
        const arraySells = [];
        arrayProducts.forEach((pro) => {
          const filter = thisPeriodRange.filter((x) => x.product === pro);
          const sells = filter.reduce((acc, value) => acc + value.quantity, 0);
          arraySells.push(sells);
        });
        const datas = [];
        for (var i2 = 0; i2 < arrayProducts.length; i2++) {
          datas.push({
            product: arrayProducts[i2],
            quantity: arraySells[i2],
          });
        }
        setThisPrediodData(datas);
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, [sDate, eDate, authToken, currentUser, props.route]);

  useLayoutEffect(() => {
    /* Chart code */
    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    let root = am5.Root.new("ProductsDonutMeli");

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        radius: am5.percent(90),
        innerRadius: am5.percent(50),
        layout: root.horizontalLayout,
      })
    );

    // Create series
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: "Series",
        valueField: "sales",
        categoryField: "country",
      })
    );

    // Set data
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
    series.data.setAll(
      thisPeriodData.map((item) => ({
        country: item.product,
        sales: item.quantity,
      }))
    );

    // Disabling labels and ticks
    series.labels.template.set("visible", false);
    series.ticks.template.set("visible", false);

    // Adding gradients
    series.slices.template.set("strokeOpacity", 0);
    series.slices.template.set(
      "fillGradient",
      am5.RadialGradient.new(root, {
        stops: [
          {
            brighten: -0.8,
          },
          {
            brighten: -0.8,
          },
          {
            brighten: -0.5,
          },
          {
            brighten: 0,
          },
          {
            brighten: -0.5,
          },
        ],
      })
    );

    // Create legend
    // https://www.amcharts.com/docs/v5/charts/percent-charts/legend-percent-series/
    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerY: am5.percent(50),
        y: am5.percent(50),
        marginTop: 15,
        marginBottom: 15,
        layout: root.verticalLayout,
        visible: false,
      })
    );

    legend.data.setAll(series.dataItems);

    // Play initial series animation
    // https://www.amcharts.com/docs/v5/concepts/animations/#Animation_of_series
    series.appear(1000, 100);

    root.current = root;

    return () => {
      root.dispose();
    };
  }, [thisPeriodData]);

  return (
    <div>
      <div id="ProductsDonutMeli" style={{ width: "100%", height: "400px" }}></div>
    </div>
  );
};

export default ProductsDonutData;
