import React, { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilMenu } from "@coreui/icons";
import { AuthContext } from "../Contexts/Auth";
import AppHeaderDropdown from "../Layout/AppHeaderDropdown";
import { logo } from "../assets/logo.js";
import Datepicker from "../components/Datepicker/Datepicker";
import useWindowWidthAndHeight from "../components/useWindowWidthAndHeight/useWindowWidthAndHeight";

const AppHeader = () => {
  const dispatch = useDispatch();
  const sidebarShow = useSelector((state) => state.sidebarShow);
  const { sDate, eDate } = useContext(AuthContext);
  const [width] = useWindowWidthAndHeight();


  return (
    <>
      {width > 1000 ? (
        <CHeader position="sticky" className="mb-4">
          <CContainer fluid>
            <CHeaderToggler
              className="ps-1"
              onClick={() => dispatch({ type: "set", sidebarShow: !sidebarShow })}
            >
              <CIcon icon={cilMenu} size="lg" />
            </CHeaderToggler>
            <CHeaderBrand className="mx-auto d-md-none" to="/">
              <CIcon icon={logo} height={48} alt="Logo" />
            </CHeaderBrand>
            <CHeaderNav className="d-none d-md-flex me-auto">
            </CHeaderNav>
            <CHeaderNav>
              <CNavItem>
                <div className="small text-medium-emphasis">
                  <h5 className="fechasPicker">
                    {new Date(sDate).toLocaleDateString()}-
                    {new Date(eDate).toLocaleDateString()}
                  </h5>
                </div>
              </CNavItem>
            </CHeaderNav>
            <CNavLink>
              <Datepicker />
            </CNavLink>
            <CHeaderNav className="ms-3">
              <AppHeaderDropdown />
            </CHeaderNav>
          </CContainer>
        </CHeader>
      ) : (
        <CHeader position="sticky" className="mb-4">
            <CHeaderNav>
            <CHeaderToggler
              className="ps-1"
              onClick={() => dispatch({ type: "set", sidebarShow: !sidebarShow })}
            >
              <CIcon icon={cilMenu} size="lg" />
            </CHeaderToggler>
              <CNavItem>
                <div className="small text-medium-emphasis">
                  <h6 className="fechasPicker">
                    {new Date(sDate).toLocaleDateString()}-
                    {new Date(eDate).toLocaleDateString()}
                  </h6>
                </div>
              </CNavItem>
            <CNavLink>
              <Datepicker />
            </CNavLink>
              <AppHeaderDropdown />
            </CHeaderNav>
        </CHeader>
      )}
    </>
  );
};

export default AppHeader;
